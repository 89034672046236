<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="box">
              <div class="field">
                <label for="" class="label">{{ $t('register.firstName') }}</label>
                <div class="control has-icons-left">
                  <input type="text" placeholder="Jan" class="input" required v-model="first_name">
                  <span class="icon is-small is-left">
                  <i class="fa fa-user"></i>
                </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">{{ $t('register.lastName') }}</label>
                <div class="control has-icons-left">
                  <input type="text" placeholder="Kowalski" class="input" required v-model="last_name">
                  <span class="icon is-small is-left">
                  <i class="fa fa-signature"></i>
                </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">{{ $t('register.email') }}</label>
                <div class="control has-icons-left">
                  <input type="email" placeholder="twojemail@poczta.pl" class="input" required v-model="email"
                         v-on:keyup.enter="register">
                  <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">{{ $t('register.password') }}</label>
                <div class="control has-icons-left">
                  <input type="password" placeholder="*******" class="input" required v-model="password"
                         v-on:keyup.enter="register">
                  <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                </div>
              </div>
              <div class="columns field">
                <div class="column">
                  <button class="button is-inverted is-primary is-fullwidth" @click="$router.push('Login')">
                    {{ $t('register.login') }}
                  </button>
                </div>
                <div class="column">
                  <button class="button is-primary is-fullwidth" @click="register">
                    {{ $t('register.register') }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    };
  },
  methods: {
    async register() {
      try {
        await this.$api.register(this.first_name, this.last_name, this.email.toLowerCase(), this.password);
        await this.$router.push('/');
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Ten email już istnieje',
          position: 'is-top',
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style scoped>
section {
  background-image: url('../assets/wallpaper.jpg');
  background-size: cover;
}
</style>
